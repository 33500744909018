import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import YouTube from "react-youtube"
import Layout from "../components/layout"
import ImgPhone from "../../static/images/mockup-frame.png"
import ImgFAQs from "../../static/images/faqs.jpg"
import ImgGlossary from "../../static/images/glossary.jpg"
import ImgCallum from "../../static/images/callum.png"
import ImgOscar from "../../static/images/oscar.png"
import ImgAisha from "../../static/images/aisha.png"
import BlueLine from "../../static/images/blue-line.svg"
import StoriesDots from "../../static/images/stories-dots.svg"
import PhoneDots from "../../static/images/phone-dots.svg"
import YellowDot from "../../static/images/large-yellow-dot.svg"
import { AnimateOnEntry } from "../components/animatedImageOnScroll"
import { ImageScroller } from "../components/imageScroller"

const VideoExample = "OtUIIk1KlMk"

const Explore = () => (
  <Layout>
    <Helmet>
      <title>Explore IBDmate</title>
    </Helmet>
    <div className="layout-explore">
      <section className="white-bg" id="intro">
        <div className="wrapper">
          <div className="content">
            <h1>Explore IBDmate</h1>
            <p>Everything you need to know in one place</p>
            <p>Our courses cover a huge range of topics and are delivered by IBD professionals and young patients.</p>
            <p>Complete the courses in order or dip in and out of what interests you.</p>
            <p>Courses available include:</p>
            <ul>
              <li>Diagnosis journey</li>
              <li>Understanding IBD</li>
              <li>Symptoms of IBD</li>
              <li>Treatment (medications and liquid diet)</li>
              <li>Tests and procedures</li>
              <li>Complications of IBD</li>
              <li>Patient stories</li>
            </ul>
            <p>More courses to be added soon</p>
          </div>
          <ImageScroller />
        </div>
      </section>
      <section className="blue-bg" id="videos">
        <div className="wrapper">
          <YouTube videoId={VideoExample} containerClassName="video" />
          <div className="content">
            <h2>Putting IBD information in your hands</h2>
            <p>
              Videos and written content explore topics in depth, helping you
              build confidence in managing your IBD between hospital
              appointments.
            </p>
            <Link to="/videos/" className="btn btn-purple">
              View more example videos
            </Link>
          </div>
        </div>
      </section>
      <section
        className="white-bg"
        id="stories"
        style={{ backgroundImage: `url(${BlueLine}),url(${StoriesDots})` }}
      >
        <div className="wrapper">
          <div className="story-content">
            <h2>Hear from patients</h2>
            <p>
              Young patients share their experiences of life with IBD and tips
              they’ve picked up along the way.
            </p>
          </div>
          <div className="story-images">
            <img src={ImgAisha} alt="Aisha" />
            <img src={ImgOscar} alt="Oscar" />
            <img src={ImgCallum} alt="Callum" />
            <Link to="/patients/" className="btn btn-purple">See our patients</Link>
          </div>
        </div>
      </section>
      <section className="blue-bg" id="faqs">
        <div className="wrapper">
          <AnimateOnEntry>
            <div
              className="preview"
              style={{ backgroundImage: `url(${PhoneDots}),url(${YellowDot})` }}
            >
              <img
                src={ImgPhone}
                alt="phone displaying app"
                className="phone"
              />
              <div className="screen">
                <img src={ImgFAQs} alt="phone displaying app" className="faq" />
              </div>
            </div>
          </AnimateOnEntry>
          <div className="content">
            <h2>Quick, simple answers</h2>
            <p>
              Our FAQ section answers common questions asked by other patients
              and their families.
            </p>
          </div>
        </div>
      </section>
      <section className="white-bg" id="glossary">
        <div className="wrapper">
          <div className="content">
            <h2>Medical jargon explained</h2>
            <p>Scratching your head about what ESR, exclusive enteral nutrition or faecal calprotectin are?</p>
            <p>Use our glossary to interpret complex medical terms used by doctors and nurses.</p>
          </div>
          <AnimateOnEntry>
            <div
              className="preview"
              style={{ backgroundImage: `url(${PhoneDots}),url(${YellowDot})` }}
            >
              <img
                src={ImgPhone}
                alt="phone displaying app"
                className="phone"
              />
              <div className="screen">
                <img
                  src={ImgGlossary}
                  alt="phone displaying app"
                  className="faq"
                />
              </div>
            </div>
          </AnimateOnEntry>
        </div>
      </section>
    </div>
  </Layout>
)

export default Explore
